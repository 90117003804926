import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { GSSearchField } from './GSSearchField'
import Icon from '../../Primitive/Icon'
import GSFilterChecklist from '../GSFilters/GSFilterChecklist'
import GSFilterToggleList from '../GSFilters/GSFilterToggleList'
import GSFilterSelect from '../GSFilters/GSFilterSelect'
import GSButton from '../GSButton'
import GSFiltersMobile from '../GSFilters/GSFiltersMobile'

function GSSearch(props) {
  const {
    value,
    setValue,
    onSearch,
    hasSearched,
    setHasSearched,
    onSuggestionClick,
    onRecentSearchClick,
    onResetFilters,
    suggestions = [],
    recentSearches = [],
    isSuggestionsLoading,
    filters,
    onFilterChange,
    onClearFilter,
    onSubmitFilters,
    setIsMapView,
    isMapView,
    onToggleLocation,
    isLocationUsed,
    isLocationLoading,
    isGeolocationAvailable,
    isGeolocationEnabled,
    locationError,
    gourmetType,
  } = props

  const [suggestionsVisible, setSuggestionsVisible] = React.useState(false)
  const [mobileFiltersOpen, setMobileFiltersOpen] = React.useState(false)

  const hasActiveFilters = Object.keys(filters).some((filterKey) =>
    filters[filterKey].some((filter) => filter.checked)
  )

  return (
    <div className="GSSearch">
      <div className="GSSearchArea">
        <GSSearchField
          value={value}
          setValue={setValue}
          onSearch={onSearch}
          placeholder={
            gourmetType === 'coffee-shop'
              ? 'Search coffee shops'
              : 'Search restaurants'
          }
          onSuggestionClick={onSuggestionClick}
          onRecentSearchClick={onRecentSearchClick}
          suggestions={suggestions}
          suggestionsVisible={suggestionsVisible}
          setSuggestionsVisible={setSuggestionsVisible}
          isSuggestionsLoading={isSuggestionsLoading}
          recentSearches={recentSearches}
          hasSearched={hasSearched}
          setHasSearched={setHasSearched}
          onToggleLocation={onToggleLocation}
          isLocationUsed={isLocationUsed}
          isLocationLoading={isLocationLoading}
          isGeolocationAvailable={isGeolocationAvailable}
          isGeolocationEnabled={isGeolocationEnabled}
          locationError={locationError}
        />
        {!suggestionsVisible && (
          <button
            className={classNames(
              'GSSearchMobileFiltersButton',
              hasActiveFilters && 'GSSearchMobileFiltersButton--active'
            )}
            onClick={() => setMobileFiltersOpen(true)}
          >
            <Icon
              type="GSConfigure"
              width={16}
              height={16}
              a11yText="Open filters"
            />
          </button>
        )}
      </div>
      {hasActiveFilters && (
        <div className="GSSearchActiveFilters">
          <div className="GSSearchActiveFilterWrapper">
            <button
              className="GSSearchActiveFiltersClearAll"
              onClick={() => onResetFilters(onSubmitFilters)}
            >
              Clear all
            </button>
            {Object.keys(filters).map((filterKey) => {
              const hasActive = filters[filterKey].some(
                (filter) => filter.checked
              )
              return (
                hasActive && (
                  <div key={filterKey} className="GSSearchActiveFilterGroup">
                    {filters[filterKey]
                      .filter((f) => f.checked)
                      .map((filter) => (
                        <GSButton
                          key={filter.value}
                          className="GSSearchActiveFilterButton"
                          variant="primary"
                          size="sm"
                          onClick={() =>
                            onFilterChange(
                              filterKey,
                              filter,
                              'multiple',
                              onSubmitFilters
                            )
                          }
                        >
                          <span>{filter.label}</span>
                          <Icon
                            type="Close"
                            width={12}
                            height={12}
                            a11yText="Remove filter"
                          />
                        </GSButton>
                      ))}
                  </div>
                )
              )
            })}
          </div>
        </div>
      )}
      <div className="GSSearchAreaActions">
        <div className="GSSearchFilters">
          {filters.offerType && filters.offerType.length > 0 && (
            <GSFilterChecklist
              label="Offer type"
              onSubmit={onSubmitFilters}
              options={filters.offerType}
              onOptionChange={(option) => onFilterChange('offerType', option)}
              onClear={() => onClearFilter('offerType')}
            />
          )}
          {filters.serviceType && filters.serviceType.length > 0 && (
            <GSFilterChecklist
              label="Service type"
              onSubmit={onSubmitFilters}
              options={filters.serviceType}
              onOptionChange={(option) => onFilterChange('serviceType', option)}
              onClear={() => onClearFilter('serviceType')}
            />
          )}
          {filters.daysAvailable && filters.daysAvailable.length > 0 && (
            <GSFilterChecklist
              label="Availability"
              onSubmit={onSubmitFilters}
              options={filters.daysAvailable}
              onOptionChange={(option) =>
                onFilterChange('daysAvailable', option)
              }
              onClear={() => onClearFilter('daysAvailable')}
            />
          )}
          {filters.cuisine && filters.cuisine.length > 0 && (
            <GSFilterToggleList
              label="Cuisine"
              searchEnabled
              onSubmit={onSubmitFilters}
              options={filters.cuisine}
              onOptionChange={(option) => onFilterChange('cuisine', option)}
              onClear={() => onClearFilter('cuisine')}
            />
          )}
          {filters.diners && filters.diners.length > 0 && (
            <GSFilterSelect
              label="Party size"
              options={filters.diners}
              onOptionChange={(option) => {
                onFilterChange('diners', option, 'single', onSubmitFilters)
              }}
            />
          )}
          {filters.chain && filters.chain.length > 0 && (
            <GSFilterChecklist
              label={
                gourmetType === 'coffee-shop' ? 'Cafe type' : 'Restaurant type'
              }
              searchEnabled
              onSubmit={onSubmitFilters}
              primaryOptions={filters.restaurantType}
              options={filters.chain}
              onOptionChange={(option) => onFilterChange('chain', option)}
              onPrimaryOptionChange={(option) =>
                onFilterChange('restaurantType', option, 'single')
              }
              onClear={() => {
                onClearFilter('chain')
                onClearFilter('restaurantType')
              }}
            />
          )}
        </div>
        <GSButton
          className={classNames(
            'GSMapButton',
            isMapView && 'GSMapButton--active'
          )}
          onClick={() => setIsMapView(!isMapView)}
          variant="primary"
        >
          {isMapView ? 'List' : 'Map'}
          <Icon
            className="GSMapButtonIcon"
            type={isMapView ? 'GSList' : 'GSMap'}
            width={isMapView ? 12 : 16}
            height={isMapView ? 12 : 16}
            a11yText="Switch to map view"
          />
        </GSButton>
      </div>
      {mobileFiltersOpen && (
        <GSFiltersMobile
          onSubmit={onSubmitFilters}
          onClearAll={() => onResetFilters(onSubmitFilters)}
          onClear={onClearFilter}
          onOpenChange={setMobileFiltersOpen}
          isOpen={mobileFiltersOpen}
          filters={filters}
          onFilterChange={onFilterChange}
          gourmetType={gourmetType}
        />
      )}
    </div>
  )
}

GSSearch.propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.string,
  onSearch: PropTypes.func,
  onSuggestionClick: PropTypes.func,
  onRecentSearchClick: PropTypes.func,
  hasSearched: PropTypes.bool,
  setHasSearched: PropTypes.func,
  isSuggestionsLoading: PropTypes.bool,
  gourmetType: PropTypes.oneOf(['restaurant', 'coffee-shop']),
  recentSearches: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['location', 'restaurant']),
      image: PropTypes.string,
      name: PropTypes.string,
      location: PropTypes.string,
    })
  ),
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['location', 'restaurant']),
      image: PropTypes.string,
      name: PropTypes.string,
      location: PropTypes.string,
    })
  ),
  filters: PropTypes.shape({
    offerType: PropTypes.array,
    daysAvailable: PropTypes.array,
    cuisine: PropTypes.array,
    diners: PropTypes.array,
    chain: PropTypes.array,
    restaurantType: PropTypes.array,
    serviceType: PropTypes.array,
  }),
  onToggleLocation: PropTypes.func,
  isLocationUsed: PropTypes.bool,
  isLocationLoading: PropTypes.bool,
  isGeolocationAvailable: PropTypes.bool,
  isGeolocationEnabled: PropTypes.bool,
  locationError: PropTypes.string,
  onFilterChange: PropTypes.func,
  onClearFilter: PropTypes.func,
  onResetFilters: PropTypes.func,
  onSubmitFilters: PropTypes.func,
  setIsMapView: PropTypes.func,
  isMapView: PropTypes.bool,
}

export default GSSearch
