import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from '../../Primitive/Icon'

function GSCard(props) {
  const {
    name,
    address,
    cuisines,
    image,
    offer,
    distance,
    onClick,
    className,
  } = props

  return (
    <button
      onClick={onClick}
      aria-label="View offer"
      className={classNames('GSCard', className)}
    >
      <div className="GSCardImage">
        <span className="GSOfferImageOverlay" />
        <div className="GSCardOfferType">{offer.name}</div>
        <img src={image} alt={name} />
      </div>
      <div className="GSCardContent">
        <div className="GSCardInfoWrapper">
          <div className="GSCardInfo">
            <h2 className="GSCardName">{name}</h2>
            {distance && (
              <div className="GSCardDistance">
                <Icon
                  type="Location"
                  width={12}
                  height={12}
                  a11yText="Location"
                />{' '}
                {distance}
              </div>
            )}
          </div>
          <div className="GSCardAddress">{address}</div>
        </div>

        <div className="GSCardFeatures">
          {offer.formattedAvailability && (
            <div className="GSCardFeature">
              <span className="GSCardFeatureName">Availability</span>
              <span className="GSCardFeatureValue">
                {offer.formattedAvailability}
              </span>
            </div>
          )}
          {/* Only show the 1st cuisine if exist */}
          {cuisines && cuisines.length > 0 && (
            <div className="GSCardFeature">
              <span className="GSCardFeatureName">Cuisines</span>
              <span className="GSCardFeatureValue">{cuisines[0].name}</span>
            </div>
          )}

          {/* Service type only gets shown for cafe */}
          {/* {offer.availableServices && (
            <div className="GSCardFeature">
              <span className="GSCardFeatureName">Service type</span>
              <span className="GSCardFeatureValue">
                {offer.availableServices.join(', ')}
              </span>
            </div>
          )} */}
        </div>
      </div>
    </button>
  )
}

GSCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  image: PropTypes.string,
  offerType: PropTypes.string,
  distance: PropTypes.string,
  cuisines: PropTypes.array,
  onClick: PropTypes.func,
  offer: PropTypes.shape({
    name: PropTypes.string,
    formattedAvailability: PropTypes.string,
    availableServices: PropTypes.array,
  }),
}

export default GSCard
