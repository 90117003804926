import React from 'react'
import { useGeolocated } from 'react-geolocated'
// import getAddressFromCoords from './get-address-from-coords'

// Need to enable this flag in chrome://flags/#unsafely-treat-insecure-origin-as-secure
// For the geolocation to work on localhost

function useGeolocation({ onComplete, onError }) {
  const [locationError, setLocationError] = React.useState('')
  const [isLocationLoading, setIsLocationLoading] = React.useState(false)

  const { getPosition, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      suppressLocationOnMount: true,
      userDecisionTimeout: 5000,
      onSuccess: async (position) => {
        // const address = await getAddressFromCoords(
        //   position.coords.latitude,
        //   position.coords.longitude
        // )

        onComplete({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          // name: address
        })

        setIsLocationLoading(false)
        setLocationError('')
      },
      onError: () => {
        setIsLocationLoading(false)
        setLocationError('Failed to get your location')
        onError && onError()
      },
    })

  const getLocation = () => {
    getPosition()
    setIsLocationLoading(true)
  }

  return {
    locationError,
    isLocationLoading,
    getLocation,
    isGeolocationAvailable,
    isGeolocationEnabled,
  }
}

export default useGeolocation
