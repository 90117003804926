import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'
import FocusTrap from 'focus-trap-react'
import useEscapeKey from '../../../../hook/useEscapeKey'
import GSFiltersMobilePanel from './GSFiltersMobilePanel'
import GSCheckbox from '../GSCheckbox'
import GSButton from '../../GSButton'
import { GSFiltersMobileFilterView } from './GSFiltersMobileFilterView'

const popularCuisines = [
  'American',
  'British',
  'Chinese',
  'French',
  'Greek',
  'Indian',
  'Italian',
  'Japanese',
  'Mexican',
  'Spanish',
]

function GSFiltersMobile(props) {
  const {
    onOpenChange,
    isOpen,
    onClearAll,
    onFilterChange,
    onSubmit,
    filters,
    gourmetType,
  } = props

  const [cuisineOpen, setCuisineOpen] = React.useState(false)
  const [chainOpen, setChainOpen] = React.useState(false)

  const focusRef = React.useRef(null)

  useEscapeKey(() => onOpenChange(false))

  const filterViewOpen = cuisineOpen || chainOpen

  // Map static popular cuisines to the current cuisine filters
  const popularCuisineOptions = useMemo(
    () =>
      popularCuisines
        .map((cuisine) => {
          const option = filters.cuisine.find(
            (option) => option.label === cuisine
          )
          if (!option) return null
          return {
            label: cuisine,
            value: option.value,
            checked: option.checked,
          }
        })
        .filter(Boolean),
    [filters.cuisine]
  )

  return createPortal(
    <RemoveScroll>
      <FocusTrap>
        <div className="GSFiltersMobileWrapper">
          <div
            className="GSFiltersMobileOverlay"
            onClick={() => onOpenChange(false)}
            role="presentation"
          />
          <aside
            role="dialog"
            aria-hidden={isOpen ? 'true' : 'false'}
            className="GSFiltersMobile"
          >
            {!filterViewOpen && (
              <GSFiltersMobilePanel
                label="Filters"
                submitLabel="Apply filters"
                focusRef={focusRef}
                onClear={onClearAll}
                onSubmit={onSubmit}
                onOpenChange={onOpenChange}
              >
                {filters.offerType && filters.offerType.length > 0 && (
                  <div className="GSFiltersMobileFilter">
                    <div className="GSFiltersMobileFilterLabel">Offer type</div>
                    <div className="GSFiltersMobileFilterColumn">
                      {filters.offerType.map((option) => (
                        <GSCheckbox
                          key={option.label}
                          className="GSFilterMobileCheckbox"
                          id={option.label}
                          name={option.label}
                          label={option.label}
                          value={option.value}
                          checked={option.checked}
                          onChange={() => onFilterChange('offerType', option)}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {filters.serviceType && filters.serviceType.length > 0 && (
                  <div className="GSFiltersMobileFilter">
                    <div className="GSFiltersMobileFilterLabel">
                      Service type
                    </div>
                    <div className="GSFiltersMobileFilterColumn">
                      {filters.serviceType.map((option) => (
                        <GSCheckbox
                          key={option.label}
                          className="GSFilterMobileCheckbox"
                          id={option.label}
                          name={option.label}
                          label={option.label}
                          value={option.value}
                          checked={option.checked}
                          onChange={() => onFilterChange('serviceType', option)}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {filters.daysAvailable && filters.daysAvailable.length > 0 && (
                  <div className="GSFiltersMobileFilter">
                    <div className="GSFiltersMobileFilterLabel">
                      Availability
                    </div>
                    <div className="GSFiltersMobileFilterColumn">
                      {filters.daysAvailable.map((option) => (
                        <GSCheckbox
                          key={option.label}
                          className="GSFilterMobileCheckbox"
                          id={option.label}
                          name={option.label}
                          label={option.label}
                          value={option.value}
                          checked={option.checked}
                          onChange={() =>
                            onFilterChange('daysAvailable', option)
                          }
                        />
                      ))}
                    </div>
                  </div>
                )}
                {filters.cuisine && filters.cuisine.length > 0 && (
                  <div className="GSFiltersMobileFilter">
                    <div className="GSFiltersMobileFilterLabel">Cuisine</div>
                    {/* For selected items */}
                    {filters.cuisine.filter((option) => option.checked).length >
                      0 && (
                      <div className="GSFiltersMobileFilterRow">
                        {filters.cuisine
                          .filter((option) => option.checked)
                          .map((option) => (
                            <GSButton
                              key={option.label}
                              className="GSFilterToggleListItem"
                              value={option.value}
                              variant="primary"
                              onClick={() => onFilterChange('cuisine', option)}
                              size="sm"
                            >
                              {option.label}
                            </GSButton>
                          ))}
                      </div>
                    )}
                    {/* "Popular" cuisines */}
                    <div className="GSFiltersMobileFilterRow">
                      {popularCuisineOptions
                        .filter((option) => !option.checked)
                        .map((option) => (
                          <GSButton
                            key={option.label}
                            className="GSFilterToggleListItem"
                            value={option.value}
                            variant="outline"
                            onClick={() => onFilterChange('cuisine', option)}
                            size="sm"
                          >
                            {option.label}
                          </GSButton>
                        ))}
                    </div>
                    <button
                      onClick={() => setCuisineOpen((prev) => !prev)}
                      className="GSFiltersMobileFilterSeeMore"
                    >
                      See more
                    </button>
                  </div>
                )}
                {filters.diners && filters.diners.length > 0 && (
                  <div className="GSFiltersMobileFilter">
                    <div className="GSFiltersMobileFilterLabel">Party size</div>
                    <div className="GSFiltersMobileFilterRow PartySize">
                      {filters.diners.slice(0, 10).map((option) => (
                        <GSButton
                          key={option.label}
                          className="GSFilterToggleListItem"
                          value={option.value}
                          variant={option.checked ? 'primary' : 'outline'}
                          onClick={() =>
                            onFilterChange('diners', option, 'single')
                          }
                          size="sm"
                        >
                          {option.label.slice(0, 1)}
                        </GSButton>
                      ))}
                    </div>
                  </div>
                )}
                {filters.restaurantType && filters.restaurantType.length > 0 && (
                  <div className="GSFiltersMobileFilter">
                    <div className="GSFiltersMobileFilterLabel">
                      {gourmetType === 'coffee-shop' ? 'Cafe' : 'Restaurant'}{' '}
                      Type
                    </div>
                    <div className="GSFiltersMobileFilterRow">
                      {filters.restaurantType.map((option) => (
                        <GSButton
                          key={option.label}
                          className="GSFilterToggleListItem"
                          value={option.value}
                          variant={option.checked ? 'primary' : 'outline'}
                          onClick={() =>
                            onFilterChange('restaurantType', option, 'single')
                          }
                          size="sm"
                        >
                          {option.label}
                        </GSButton>
                      ))}
                    </div>
                    <button
                      onClick={() => setChainOpen((prev) => !prev)}
                      className="GSFiltersMobileFilterSeeMore"
                    >
                      See more
                    </button>
                  </div>
                )}
              </GSFiltersMobilePanel>
            )}
            {cuisineOpen && (
              <GSFiltersMobileFilterView
                label="Cuisine"
                submitLabel="Apply filter"
                onBack={() => setCuisineOpen(false)}
                onOptionChange={(option) => onFilterChange('cuisine', option)}
                options={filters.cuisine}
                onOpenChange={onOpenChange}
                onSubmit={() => {
                  setCuisineOpen(false)
                  onSubmit()
                }}
                searchEnabled
                filterViewOpen={filterViewOpen}
              />
            )}
            {chainOpen && (
              <GSFiltersMobileFilterView
                label={
                  gourmetType === 'coffee-shop'
                    ? 'Cafe type'
                    : 'Restaurant type'
                }
                submitLabel="Apply filter"
                searchEnabled
                onBack={() => setChainOpen(false)}
                options={filters.chain}
                primaryOptions={filters.restaurantType}
                onOpenChange={onOpenChange}
                onSubmit={() => {
                  setChainOpen(false)
                  onSubmit()
                }}
                onOptionChange={(option) => onFilterChange('chain', option)}
                onPrimaryOptionChange={(option) =>
                  onFilterChange('restaurantType', option)
                }
                filterViewOpen={filterViewOpen}
              />
            )}
          </aside>
        </div>
      </FocusTrap>
    </RemoveScroll>,
    document.body
  )
}

GSFiltersMobile.propTypes = {
  onSubmit: PropTypes.func,
  onOpenChange: PropTypes.func,
  isOpen: PropTypes.bool,
  gourmetType: PropTypes.oneOf(['restaurant', 'coffee-shop']),
  filters: PropTypes.shape({
    offerType: PropTypes.array,
    daysAvailable: PropTypes.array,
    cuisine: PropTypes.array,
    diners: PropTypes.array,
    chain: PropTypes.array,
    restaurantType: PropTypes.array,
  }),
  onFilterChange: PropTypes.func,
  onClear: PropTypes.func,
  onClearAll: PropTypes.func,
}

export default GSFiltersMobile
