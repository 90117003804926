import React from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import Icon from '../../Primitive/Icon'
import GSButton from '../GSButton'
import { RemoveScroll } from 'react-remove-scroll'
import FocusTrap from 'focus-trap-react'
import useEscapeKey from '../../../hook/useEscapeKey'
import { GSMembershipPanel } from '../GSMembershipPanel'
import { GSMembershipCard } from '../GSMembershipPanel/GSMembershipCard'
import { GSMembershipOfferQR } from '../GSMembershipPanel/GSMembershipOfferQR'
import { Map, Marker } from '@vis.gl/react-google-maps'
import getRedemptionCode from '../lib/get-redemption-code'

const supportedRedemptions = ['MEMBERSHIP', 'ECODE']

const statusMap = {
  needsMembership: `Please set up your Gourmet Society Membership to claim this offer`,
  needsVip: `You need to have VIP access to claim this offer`,
}

function GSOfferPanel(props) {
  const { onOpenChange, restaurant, isOpen, userData, userHasGourmet } = props

  const { offer } = restaurant
  const claimMethod = offer.claimMethods[0]
  const membership = userData.memberships && userData.memberships[0]

  const focusRef = React.useRef(null)
  const [redemptionLoading, setRedemptionLoading] = React.useState(false)
  const [ECode, setECode] = React.useState(null)
  const [redemptionError, setRedemptionError] = React.useState(null)
  const [membershipPanelOpen, setMembershipPanelOpen] = React.useState(false)

  const handleClaimOffer = async () => {
    if (claimMethod.type === 'MEMBERSHIP') {
      setMembershipPanelOpen(true)
    }

    if (claimMethod.type === 'ECODE') {
      setRedemptionLoading(true)
      setRedemptionError(null)
      const result = await getRedemptionCode({
        membershipId: membership.id,
        ratePlanId: offer.redemption.ratePlanId,
        // ratePlanId: 'a2kTA0000000JlpYAE',
      })
      if (result.error || !result.code) {
        setRedemptionError('There was an error redeeming the offer')
        setRedemptionLoading(false)
        return
      }

      setRedemptionLoading(false)
      setECode(result.code)
      setMembershipPanelOpen(true)
    }
  }

  const handleSetupMembershipClick = () => {
    onOpenChange(false)
    const redemptionPanel = document.querySelector('.RedemptionPanel')
    if (!redemptionPanel) return
    redemptionPanel.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  useEscapeKey(() => onOpenChange(false))

  const isEligible = props.isSubscriber && props.hasVipAccess
  const isSupported = supportedRedemptions.includes(claimMethod.type)

  const details = [
    offer.formattedAvailability && `${offer.formattedAvailability}`,
    offer.maxDiners && offer.maxDiners === 2
      ? `2 People`
      : `2–${offer.maxDiners} People`,
  ].filter(Boolean)

  const exclusions = [
    offer.genericExclusions.excludeBankHoliday === false && 'Bank holidays',
    offer.genericExclusions.excludeDecember === false && 'December',
    offer.genericExclusions.excludeCelebration === false && 'Celebration days',
  ].filter(Boolean)

  const venueDetails = [
    restaurant.cuisines &&
      restaurant.cuisines.length > 0 &&
      restaurant.cuisines.map((cuisine) => cuisine.name),
    offer.availableServices &&
      offer.availableServices.length > 0 &&
      offer.availableServices.map((service) => service.name),
    restaurant.bookingTelephone && restaurant.bookingTelephone,
  ]
    .flat()
    .filter(Boolean)

  return createPortal(
    <RemoveScroll>
      <FocusTrap>
        <div className="GSOfferPanelWrapper">
          <div
            className="GSOfferPanelOverlay"
            onClick={() => onOpenChange(false)}
            role="presentation"
          />
          <aside
            role="dialog"
            aria-hidden={isOpen ? 'true' : 'false'}
            className="GSOfferPanel"
          >
            <div className="GSOfferPanelInner">
              <div className="GSOfferPanelImage">
                <img src={restaurant.image} alt={restaurant.name} />
                <span className="GSOfferPanelImageOverlay" />
                <div className="GSPOfferPanelType">{offer.name}</div>
              </div>

              <button
                onClick={() => onOpenChange(false)}
                tabIndex={0}
                ref={focusRef}
                aria-label="Close panel"
                className="GSOfferPanelClose"
              >
                <Icon a11yText="Close" type="Close" width={10} height={10} />
              </button>

              <div className="GSOfferPanelContent">
                <h2 className="GSOfferPanelTitle">{restaurant.name}</h2>
                <div className="GSOfferPanelDetails">
                  <div className="GSOfferPanelDetailsTitle">
                    Important Details
                  </div>
                  {details.length > 0 && exclusions.length > 0 && (
                    <div className="GSOfferPanelDetailsWrapper">
                      {details.length > 0 && (
                        <div className="GSOfferPanelDetailsItems">
                          {details.map((detail) => (
                            <div
                              key={detail}
                              className="GSOfferPanelDetailsItem"
                            >
                              {detail}
                            </div>
                          ))}
                        </div>
                      )}
                      {exclusions.length > 0 && (
                        <div className="GSOfferPanelDetailsItems">
                          {exclusions.map((exclusion) => (
                            <div
                              key={exclusion}
                              className="GSOfferPanelDetailsItem"
                            >
                              <Icon type="Cross" width={12} height={12} />
                              {exclusion}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  {(!userHasGourmet || !props.hasVipAccess) && (
                    <div className="GSOfferPanelNotice">
                      <Icon type="Notice" width={16} height={16} />
                      {!userHasGourmet && props.hasVipAccess && (
                        <p>{statusMap.needsMembership}</p>
                      )}
                      {!props.hasVipAccess && <p>{statusMap.needsVip}</p>}
                    </div>
                  )}

                  <div className="GSOfferPanelNotice GSOfferPanelNotice--desktop">
                    <Icon type="Notice" width={16} height={16} />
                    <p>
                      Sorry, this offer cannot be claimed on a desktop browser.
                      Please open this page on your mobile phone or tablet.
                    </p>
                  </div>
                </div>

                {restaurant.geo && (
                  <div className="GSOfferPanelLocation">
                    <Map
                      mapId="gs-map-offer"
                      defaultCenter={restaurant.geo}
                      defaultZoom={14}
                      disableDoubleClickZoom={true}
                      fullscreenControl={false}
                      zoomControl={false}
                      streetViewControl={false}
                      mapTypeControl={false}
                      style={{ width: '100%', height: '100%' }}
                    >
                      <Marker position={restaurant.geo} />
                    </Map>

                    <div className="GSOfferPanelLabel">
                      <Icon type="MapPin" width={16} height={16} />
                      {restaurant.address}
                    </div>
                  </div>
                )}

                <div className="GSOfferPanelDetails">
                  <div className="GSOfferPanelDetailsTitle">Venue Details</div>
                  <div className="GSOfferPanelDetailsItems">
                    {venueDetails.map((detail) => (
                      <div key={detail} className="GSOfferPanelDetailsItem">
                        {detail}
                      </div>
                    ))}
                  </div>
                  <div className="OfferPanelDetailsDescription">
                    {restaurant.description}
                  </div>
                </div>
              </div>
            </div>

            {isEligible && (
              <div className="GSOfferPanelFooter">
                {isSupported && !userHasGourmet && (
                  <div className="GSOfferPanelFooterButtons">
                    <GSButton onClick={handleSetupMembershipClick}>
                      Set up Membership
                    </GSButton>
                  </div>
                )}
                {isSupported && userHasGourmet && claimMethod && (
                  <div className="GSOfferPanelFooterButtons">
                    {restaurant.bookingTelephone && (
                      <GSButton
                        variant="outline"
                        href={`tel:${restaurant.bookingTelephone}`}
                      >
                        Call
                      </GSButton>
                    )}
                    <GSButton
                      loading={redemptionLoading}
                      onClick={handleClaimOffer}
                    >
                      Claim Offer
                    </GSButton>
                  </div>
                )}
                {(!claimMethod || !isSupported) && (
                  <div className="GSOfferPanelFooterText">
                    This offer is not available
                  </div>
                )}
                {redemptionError && (
                  <div className="GSOfferPanelError">{redemptionError}</div>
                )}
              </div>
            )}

            {membershipPanelOpen && (
              <GSMembershipPanel
                isOpen={membershipPanelOpen}
                onOpenChange={setMembershipPanelOpen}
              >
                {claimMethod.type === 'MEMBERSHIP' ? (
                  <GSMembershipCard membership={membership} />
                ) : (
                  <GSMembershipOfferQR
                    code={ECode}
                    restaurant={restaurant}
                    // expirationDate="2024-12-31"
                    qrSrc={ECode}
                  />
                )}
              </GSMembershipPanel>
            )}
          </aside>
        </div>
      </FocusTrap>
    </RemoveScroll>,
    document.body
  )
}

GSOfferPanel.propTypes = {
  isOpen: PropTypes.bool,
  onOpenChange: PropTypes.func,
  restaurant: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    bookingTelephone: PropTypes.string,
    description: PropTypes.string,
    offer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      formattedAvailability: PropTypes.string,
      availableServices: PropTypes.array,
    }),
  }),
  userHasGourmet: PropTypes.bool,
  isSubscriber: PropTypes.bool,
  subscriptionTenure: PropTypes.number,
  hasVipAccess: PropTypes.bool,
  isUpgradable: PropTypes.bool,
  canUpgrade: PropTypes.bool,
}

export default GSOfferPanel
