import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import GSCard from './GSCard'
import GSOfferPanel from './GSOfferPanel'
import GSSearch from './GSSearch'
import useGeolocation from './lib/useGeolocation'
import Spinner from '../Primitive/Spinner'
import GSMap from './GSMap'
import getDistance from './lib/get-distance'
import formatRestaurant from './lib/format-restaurant'
import fetchRestaurants from './lib/fetch-restaurants'
import useLocalStorage from './lib/useLocalStorage'
import GSButton from './GSButton'
import formatSuggestion from './lib/format-suggestion'
import { APIProvider } from '@vis.gl/react-google-maps'

const initFilterOptions = (options, initial) =>
  options.map((option) => ({
    ...option,
    checked: initial ? (initial || []).includes(option.value) : false,
  }))

const availabilityOptions = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Everyday',
    value: 'monday,tuesday,wednesday,thursday,friday,saturday,sunday',
  },
  {
    label: 'Monday',
    value: 'monday',
  },
  {
    label: 'Tuesday',
    value: 'tuesday',
  },
  {
    label: 'Wednesday',
    value: 'wednesday',
  },
  {
    label: 'Thursday',
    value: 'thursday',
  },
  {
    label: 'Friday',
    value: 'friday',
  },
  {
    label: 'Saturday',
    value: 'saturday',
  },
  {
    label: 'Sunday',
    value: 'sunday',
  },
]

const restaurantTypeOptions = [
  {
    label: 'Independent',
    value: 'independent',
  },
  {
    label: 'Chain',
    value: 'chain',
  },
]

const serviceTypeOptions = [
  {
    label: 'Stay in', // TODO: Check if this is correct. None of the offers in staging api have this service type
    value: 'Stay-In',
  },
  {
    label: 'Dine out',
    value: 'Dine-Out',
  },
]

const partySizeOptions = Array(8)
  .fill({})
  .map((_, index) => ({
    label: `${index + 2} people`,
    value: `${index + 2}`,
  }))

const GourmetSociety = ({
  title,
  initialOffers,
  initialParams,
  userHasGourmet,
  brands,
  cuisines,
  offerTypes,
  userData,
  isSubscriber,
  hasVipAccess,
  isUpgradable,
  canUpgrade,
  gourmetType,
}) => {
  const { filters: initialFilters } = initialParams
  const intersObserver = React.useRef(null)
  const [query, setQuery] = React.useState(initialParams.q || '')
  const debounceTimerRef = React.useRef(null)
  const [isLoadingMore, setIsLoadingMore] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSuggestionsLoading, setIsSuggestionsLoading] = React.useState(false)
  const [recentSearches, setRecentSearches] = useLocalStorage({
    key: 'recentSearches',
    initialValue: [],
  })
  const [suggestions, setSuggestions] = React.useState(
    (initialParams.q && initialParams.q.length > 0
      ? initialOffers || []
      : []
    ).map(formatSuggestion)
  )
  const [bounds, setBounds] = React.useState(null)
  const [isMapView, setIsMapView] = React.useState(false)
  const [isLocationUsed, setIsLocationUsed] = React.useState(false)
  const [location, setLocation] = React.useState(null)
  const [openOffer, setOpenOffer] = React.useState(false) // False or Offer
  const [hasSearched, setHasSearched] = React.useState(false) // For showing clear input button
  const [limit, setLimit] = React.useState(initialParams.limit)
  const [page, setPage] = React.useState(initialParams.page)
  const [hasMore, setHasMore] = React.useState(initialOffers.length === limit)
  const [offers, setOffers] = React.useState(
    initialOffers.map((r) => formatRestaurant(r)).flat()
  )

  const [filters, setFilters] = React.useState({
    chain: initFilterOptions(
      brands.map((b) => ({ label: b.name, value: b.id })),
      initialFilters.chain
    ),
    restaurantType: initFilterOptions(restaurantTypeOptions, [
      initialFilters.restaurantType,
    ]),
    ...(gourmetType === 'coffee-shop' && {
      serviceType: initFilterOptions(
        serviceTypeOptions,
        initialFilters.serviceType
      ),
    }),
    ...(gourmetType === 'restaurant' && {
      offerType: initFilterOptions(
        offerTypes.map((o) => ({ label: o.name, value: o.id })),
        initialFilters.offerType
      ),
      daysAvailable: initFilterOptions(
        availabilityOptions,
        initialFilters.daysAvailable
      ),
      cuisine: initFilterOptions(
        cuisines.map((c) => ({ label: c.name, value: c.id })),
        initialFilters.cuisine
      ),
      diners: initFilterOptions(partySizeOptions, initialFilters.diners),
    }),
  })

  const {
    getLocation,
    isGeolocationAvailable,
    isGeolocationEnabled,
    locationError,
    isLocationLoading,
  } = useGeolocation({
    onComplete: async (position) => {
      setLocation(position)
      setIsLocationUsed(true)

      const queryParams = {
        query,
        filters,
        location: {
          lat: position.lat,
          lng: position.lng,
        },
      }

      handleFetchSuggestions({
        ...queryParams,
        limit: 5,
      })

      // In map view, search is done via bounds
      if (isMapView) {
        return
      }

      handleSearch({
        ...queryParams,
        page: 1,
        limit,
      })

      handleAddRecentSearch({
        name: query,
        type: 'text',
      })
      setHasSearched(true)
    },
    onError: () => {
      setIsLocationUsed(false)
    },
  })

  const handleSearch = async (params) => {
    setIsLoading(true)
    const data = await fetchRestaurants({ ...params, type: gourmetType })
    setHasMore(data.length === params.limit)
    setOffers(data.map(formatRestaurant).flat())
    setIsLoading(false)
  }

  const handleAddRecentSearch = (suggestion) => {
    const { type, name, location, image } = suggestion
    const recentSearch = {
      type: type || 'text',
      name,
      ...(location && { location }),
      ...(image && { image }),
    }
    setRecentSearches(
      [recentSearch, ...recentSearches]
        .filter((s, i, arr) => arr.findIndex((s2) => s2.name === s.name) === i)
        .slice(0, 12)
    )
  }

  const handleSubmitSearch = () => {
    setHasSearched(true)
    handleSearch({
      query,
      filters,
      page: 1,
      limit,
      ...(isMapView ? { bounds } : isLocationUsed && { location }),
    })
    if (query.length >= 4) handleAddRecentSearch({ name: query })
  }

  const handleFetchMore = async () => {
    setPage((prev) => prev + 1)
    setIsLoadingMore(true)
    const data = await fetchRestaurants({
      query,
      filters,
      page: page + 1,
      type: gourmetType,
      limit,
      ...(isLocationUsed && { location }),
    })
    setOffers((prev) => [...prev, ...data.map(formatRestaurant).flat()])
    setHasMore(data.length === limit)
    setIsLoadingMore(false)
  }

  const handleFilterChange = (type, option, mode = 'multiple', cb) => {
    setFilters((prev) => {
      const newFilters = {
        ...prev,
        [type]: prev[type].map((filter) => {
          // Toggle in 'single' mode
          if (mode === 'single') {
            // Uncheck if already checked
            if (filter.value === option.value && filter.checked) {
              return {
                ...filter,
                checked: false,
              }
            }
            return {
              ...filter,
              checked: filter.value === option.value,
            }
          }
          if (filter.value === option.value) {
            return {
              ...filter,
              checked: !filter.checked,
            }
          }
          return filter
        }),
      }

      if (cb) {
        cb(newFilters)
      }

      return newFilters
    })
  }

  const handleClearFilter = (type) => {
    setFilters((prev) => ({
      ...prev,
      [type]: prev[type].map((filter) => ({
        ...filter,
        checked: false,
      })),
    }))
  }

  const handleResetFilters = (cb) => {
    setFilters((prev) => {
      const newFilters = Object.keys(prev).reduce((acc, key) => {
        acc[key] = prev[key].map((filter) => ({
          ...filter,
          checked: false,
        }))
        return acc
      }, {})
      if (cb) {
        cb(newFilters)
      }
      return newFilters
    })
  }

  const handleSubmitFilters = (newFilters) => {
    setHasSearched(true)
    handleSearch({
      query,
      page: 1,
      limit,
      filters: newFilters || filters,
      ...(isLocationUsed && { location }),
    })
  }

  const handleToggleLocation = () => {
    setIsLocationUsed((isUsed) => {
      if (isUsed) {
        return false
      }
      getLocation()
    })
  }

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery)

    // Reset the debounce timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current)
    }

    // Set a new debounce timer so we don't make requests on every key press
    debounceTimerRef.current = setTimeout(() => {
      if (newQuery.length <= 3) return
      handleFetchSuggestions({
        query: newQuery,
        filters,
        limit: 5,
        ...(isMapView ? bounds : isLocationUsed && { location }),
      })
    }, 300)
  }

  const handleFetchSuggestions = async (params) => {
    setIsSuggestionsLoading(true)
    const data = await fetchRestaurants({
      ...params,
      ...(isLocationUsed && { location }),
      type: gourmetType,
      useUrlParams: false,
    })
    const suggestions = (data || []).map(formatSuggestion)
    setSuggestions(suggestions)
    setIsSuggestionsLoading(false)
  }

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.name)
    handleAddRecentSearch(suggestion)

    handleSearch({
      query: suggestion.name,
      filters,
      page: 1,
      limit,
      ...(isMapView ? bounds : isLocationUsed && { location }),
    })
  }

  const handleRecentSearchClick = (suggestion) => {
    setQuery(suggestion.name)
    handleSearch({
      query: suggestion.name,
      filters,
      page: 1,
      limit,
    })
  }

  const handleSetMapView = (isMapView) => {
    setIsMapView(isMapView)
    if (isMapView) {
      const topEl = document.querySelector('#GSTop')
      if (topEl) {
        topEl.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  const handleMapBoundsChange = (e) => {
    const bounds = e.detail.bounds
    setLimit(64)
    setBounds(bounds)
    handleSearch({
      query,
      filters,
      page: 1,
      limit: 64,
      ...(bounds && { bounds }),
    })
  }

  // Detect when the search container is pinned to the top
  useEffect(() => {
    const anchor = document.getElementById('GSStickyAnchor')
    const el = document.getElementById('GSSearchContainer')

    if (!intersObserver.current) {
      intersObserver.current = new IntersectionObserver(
        ([e]) => {
          el.classList.toggle('isPinned', e.boundingClientRect.y < 0)
        },
        {
          root: null,
          rootMargin: '0px 0px -100% 0px',
          threshold: 0,
        }
      )
    }

    intersObserver.current.observe(anchor)
  }, [])

  // Add distance to offers
  const formattedOffers = useMemo(
    () =>
      (offers || []).map((offer) => ({
        ...offer,
        ...(location && {
          distance: `${getDistance(location, offer.geo).toFixed(1)} mi`,
        }),
      })),
    [offers, location]
  )

  return (
    <APIProvider apiKey="AIzaSyDjaqjcRx1SoeFUDYm-f5vzBYWwovIkzlM">
      <div className="GourmetSociety">
        <h2 className="GSTitle GSContainer">{title}</h2>
        <div id="GSStickyAnchor" />
        <div id="GSTop" />
        <div id="GSSearchContainer" className="GSContainer GSSearchContainer">
          <GSSearch
            setValue={handleQueryChange}
            value={query}
            onSearch={handleSubmitSearch}
            hasSearched={hasSearched}
            setHasSearched={setHasSearched}
            recentSearches={recentSearches}
            suggestions={suggestions}
            isSuggestionsLoading={isSuggestionsLoading}
            onSuggestionClick={handleSuggestionClick}
            onRecentSearchClick={handleRecentSearchClick}
            gourmetType={gourmetType}
            // Filters
            filters={filters}
            onFilterChange={handleFilterChange}
            onClearFilter={handleClearFilter}
            onResetFilters={handleResetFilters}
            onSubmitFilters={handleSubmitFilters}
            // Location
            onToggleLocation={handleToggleLocation}
            setIsMapView={handleSetMapView}
            isMapView={isMapView}
            isLocationUsed={isLocationUsed}
            isLocationLoading={isLocationLoading}
            isGeolocationAvailable={isGeolocationAvailable}
            isGeolocationEnabled={isGeolocationEnabled}
            locationError={locationError}
          />
        </div>

        {!isMapView && (
          <div className="GSResults GSContainer">
            <div className="GSCardGrid">
              {!isLoading &&
                formattedOffers.length > 0 &&
                formattedOffers.map((offer) => (
                  <GSCard
                    onClick={() => setOpenOffer(offer)}
                    key={offer.id}
                    {...offer}
                  />
                ))}
            </div>
            {formattedOffers.length === 0 && !isLoading && (
              <div className="GSResultsNotFound">
                Could not find any results
              </div>
            )}
            {isLoading && (
              <div className="GSResultsLoading">
                <Spinner size={60} />
              </div>
            )}
            {hasMore && !isLoading && (
              <GSButton
                className="GSResultsLoadMore"
                loading={isLoadingMore}
                onClick={handleFetchMore}
              >
                Load more
              </GSButton>
            )}
          </div>
        )}

        {isMapView && (
          <GSMap
            isLocationLoading={isLocationLoading}
            location={location}
            isLoading={isLoading}
            getLocation={getLocation}
            onOpenOffer={(offer) => setOpenOffer(offer)}
            onLoadMore={handleFetchMore}
            isLoadingMore={isLoadingMore}
            hasMore={hasMore}
            gourmetType={gourmetType}
            onBoundsChanged={handleMapBoundsChange}
            offers={formattedOffers.filter(
              (o) => o.geo && o.geo.lat && o.geo.lng
            )}
          />
        )}

        {!!openOffer && (
          <GSOfferPanel
            isOpen={!!openOffer}
            onOpenChange={setOpenOffer}
            restaurant={openOffer}
            isGeolocationEnabled={isGeolocationEnabled}
            isGeolocationAvailable={isGeolocationAvailable}
            locationError={locationError}
            userHasGourmet={userHasGourmet}
            isSubscriber={isSubscriber}
            hasVipAccess={hasVipAccess}
            isUpgradable={isUpgradable}
            canUpgrade={canUpgrade}
            userData={userData}
            gourmetType={gourmetType}
          />
        )}
      </div>
    </APIProvider>
  )
}

GourmetSociety.propTypes = {
  initialPage: PropTypes.number,
  initialOffers: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  userHasGourmet: PropTypes.bool,
  brands: PropTypes.arrayOf(PropTypes.shape({})),
  cuisines: PropTypes.arrayOf(PropTypes.shape({})),
  offerTypes: PropTypes.arrayOf(PropTypes.shape({})),
  userData: PropTypes.shape({}),
  isSubscriber: PropTypes.bool,
  hasVipAccess: PropTypes.bool,
  isUpgradable: PropTypes.bool,
  canUpgrade: PropTypes.bool,
  gourmetType: PropTypes.oneOf(['restaurant', 'coffee-shop']),
  initialParams: PropTypes.shape({
    q: PropTypes.string,
    page: PropTypes.number,
    limit: PropTypes.number,
    filters: PropTypes.shape({
      serviceType: PropTypes.arrayOf(PropTypes.string),
      chain: PropTypes.arrayOf(PropTypes.string),
      restaurantType: PropTypes.string,
      offerType: PropTypes.arrayOf(PropTypes.string),
      daysAvailable: PropTypes.arrayOf(PropTypes.string),
      cuisine: PropTypes.arrayOf(PropTypes.string),
      diners: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
}

GourmetSociety.defaultProps = {
  initialParams: {
    q: '',
    limit: 12,
    page: 1,
  },
  initialOffers: [],
  title: 'Find a participating coffee shop near you',
  userHasGourmet: false,
  brands: [],
  cuisines: [],
  offerTypes: [],
  userData: {},
  subscriptionTenure: 0,
  isSubscriber: false,
  hasVipAccess: false,
  isUpgradable: false,
  canUpgrade: false,
}

export default GourmetSociety
